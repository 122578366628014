import React, { Suspense, } from 'react';
import { Routes, Route, BrowserRouter as Router } from "react-router-dom"
import { RoutesList } from './methods/UseImports';

const App = () => {

    return (
        <React.Fragment>
            <Router basename="/">
                <Routes>
                    {RoutesList.map((ele: any) => {
                        return ele.path.map((ele2: any) => {
                            return <Route key={ele2} path={ele2} element={
                                <Suspense fallback={
                                    <div className="loader">
                                    </div>
                                }>
                                    {<ele.element />}
                                </Suspense>
                            } />
                        })
                    })}
                </Routes >
            </Router >
        </React.Fragment >
    );
};

export default App;